@tailwind base;
@tailwind components;
@tailwind utilities;

.Polaris-Button--primary {
  background-color: var(--p-action-primary);
  color: var(--p-text-on-primary);
}
.Polaris-Button--primary:hover {
  background-color: var(--p-action-primary-hovered);
}
.Polaris-Button--primary:active {
  background-color: var(--p-action-primary-pressed);
}
.Polaris-Button--primary:depressed {
  background-color: var(--p-action-primary-depressed);
}
